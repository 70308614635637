<template>
  <div class="ai">
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <img src="/img/AI.png" alt="" />
        <img
          src="/img/banner4Draw.png"
          style="height: 450px; margin-top: 100px"
          alt=""
        />
      </div>
      <div class="bottom">
        <div class="item">
          <img src="/img/AI2.png" alt="" />
          <div class="text">
            <p>
              人工智能产业链主要包括基础层软硬件、技术层算法和智能技术、应用层产品服务和解决方案，从应用层看人工智能产业发展主要呈现智慧城市、智慧生产、智慧生活三大类应用领域
            </p>
          </div>
        </div>
        <div class="item">
          <img src="/img/AIData.png" alt="" />
          <div class="text">
            <p>
              大数据主要包括数据挖掘、数据采集、数据分析、数据应用等。通过对数据的分析汇总，快速搭建数据指标体系，大大提高优化的决策效率，提高生产效率，协助产品快速迭代。
            </p>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {};
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.top {
  background-image: url("/img/banner3.png");
  width: 100%;
  height: 450px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.top > img {
  height: 350px;
}
.bottom {
  width: 1200px;
  margin: 0 auto;
}
.bottom > .item {
  width: 100%;
  height: 180px;
  background-image: linear-gradient(#55cfe6, #22aaef);
  margin-top: 40px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 5px rgb(133, 129, 129);
}
.bottom > .item > .text {
  width: 640px;
  height: 79px;
  position: absolute;
  right: 120px;
  top: 58px;
}
.bottom > .item > .text > p {
  color: #ffffff;
  line-height: 25px;
}
.bottom > .item > img {
  width: 260px;
  top: 10px;
  left: 40px;
  position: absolute;
}
@media screen and (max-width: 780px) {
  .container {
    width: 1200px;
    overflow: visible;
  }
  .ai {
    width: 1200px;
  }
}
</style>