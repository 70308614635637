<template>
  <div>
    <navbar></navbar>
    <!-- 主内容 -->
    <div class="container">
      <div class="top">
        <img src="/img/banner2a.png" alt="" />
        <img src="/img/ITfuwu.png" alt="" />
      </div>
      <div class="center">
        <div class="title" style="margin-top: 10px">
          <h1>COOPERATION</h1>
          <h3 style="left: 35%">商务合作</h3>
        </div>
        <div class="show">
          <div class="left">
            <div @mousemove="showhover(1)" :class="{ sele: sele == 1 }">
              <div>提供技术支持、咨询、运维等服务，按需支付固定服务费用</div>
            </div>
            <div @mousemove="showhover(2)" :class="{ sele: sele == 2 }">
              <div>提供整体项目解决方案，独家项目合作、软件研发、费用合理</div>
            </div>
            <div @mousemove="showhover(3)" :class="{ sele: sele == 3 }">
              <div>
                帮助企业减少人力成本、促进人员稳定、保持产品稳定、专注于自身业务发展
              </div>
            </div>
          </div>
          <div class="right">
            <img :src="img" alt="" />
          </div>
        </div>
        <!-- <div class="card">
          <div class="item">
            <p>提供技术支持、咨询、运维等服务，按需支付固定服务费用</p>
          </div>
          <div class="item">
            <p>提供整体项目的解决方案，独家项目合作、项目研发、费</p>
          </div>
          <div class="item">
            <p>
              帮助企业减少人力成本、促进人员稳定、保持产品稳定、专注于自身业务发展
            </p>
          </div>
        </div> -->
      </div>
      <div class="content">
        <div class="item" style="background-image: url('/img/xinbg.png')">
          <img src="/img/xin.png" alt="" />
          <ul>
            <li><h1>标准服务</h1></li>
            <li>• 提供常规一年运维服务：故障处理、服务启停、6小时响应</li>
            <li>• 服务有效期内，提供总计不超出10天工作量的需求更新</li>
            <li>• 提供免费技术咨询、IT解决方案</li>
            <li>• 服务费用低，适应小量级需求用户</li>
          </ul>
        </div>
        <div class="item" style="background-image: url('/img/zuanshibg.png')">
          <img src="/img/zuanshi.png" alt="" />
          <ul>
            <li><h1>高级服务</h1></li>
            <li>• 提供一年常规运维服务：故障处理、服务启停、3小时响应</li>
            <li>• 服务有效期内，提供总计不超出30天工作量的需求更新</li>
            <li>• 每年不超出2次现场用户培训服务</li>
            <li>• 提供免费技术咨询、IT解决方案</li>
            <li>• 服务完善，适应更多需求量用户</li>
          </ul>
        </div>
        <div
          class="item"
          style="background-image: url('/img/huangguangbg.png')"
        >
          <img src="/img/huangguan.png" alt="" />
          <ul>
            <li><h1>尊享服务</h1></li>
            <li>
              •
              提供一年高级运维服务：故障处理、服务启停、远程指导、数据修复、1小时极速响应
            </li>
            <li>• 服务有效期内，提供总计不超出60天工作量的需求更新</li>
            <li>• 每年不超出5次现场用户培训服务</li>
            <li>• 提供免费技术咨询、IT解决方案</li>
            <li>• 畅享一对一保姆式尊享服务</li>
          </ul>
        </div>
      </div>
      <div class="subswipe">
        <div class="list">
          <div>
            <img src="/img/yongyoulogo.png" alt="" />
          </div>
          <div>
            <img src="/img/yiqifengtianlogo.png" alt="" />
          </div>
          <div>
            <img src="/img/gongshanglogo.png" alt="" />
          </div>
          <div>
            <img src="/img/zhaoshanglogo.png" alt="" />
          </div>
          <div>
            <img src="/img/nongyelogo.png" alt="" />
          </div>
          <div>
            <img src="/img/baomalogo.png" alt="" />
          </div>
          <div>
            <img src="/img/haixinlogo.png" alt="" />
          </div>
          <div>
            <img src="/img/youhanglogo.png" alt="" />
          </div>
          <div>
              <img src="/img/shenzhenranqi.png" alt="" />
            </div>
            <div>
              <img src="/img/yanghua.png" alt="" />
            </div>
            <div>
              <img src="/img/yzb.png" alt="" />
            </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {
      img: "/img/coop1.png",
      sele: 1,
    };
  },
  methods: {
    showhover(i) {
      this.img = `/img/coop${i}.png`;
      this.sele = i;
    },
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>

<style scoped>
@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
  }
}
.content {
  margin: 0 auto;
  margin-top: 60px;
  width: 1200px;
  display: flex;
  justify-content: space-around;
}
.content > .item {
  width: 320px;
  height: 320px;
  border-radius: 25px;
  color: #ffffff;
  position: relative;
  transition: 0.5s;
}
@keyframes box-shadow-2 {
  from {
    box-shadow: inset 0 0 75px 20px slategray, 0 0 0 slategray;
  }
  to {
    box-shadow: inset 0 0 35px 20px transparent, 0 0 75px 20px transparent;
  }
}
.content > .item:hover {
  width: 320px;
  height: 320px;
  border-radius: 25px;
  color: #ffffff;
  position: relative;
  margin-top: -30px;
  animation: box-shadow-2 1s infinite alternate;
  box-shadow: inset 0 0 75px 50px slategray, 0 0 0 slategray;
}
.content > .item > img {
  position: absolute;
  top: -45px;
  left: 111px;
}
.content > .item > ul {
  padding: 50px 15px;
}
.content > .item > ul > li {
  line-height: 23px;
  padding-left: 15px;
  text-indent: -10px;
  width: 275px;
}
.content > .item > ul > li > h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 32px;
}
.top {
  background-image: url("/img/banner2.png");
  background-size: cover;
  background-origin: border-box;
  background-attachment: fixed;
  width: 100%;
  height: 450px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.top > img {
  height: 350px;
}

.center {
  margin-top: 40px;
  max-width: 1920px;
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  width: 100%;
}
.center > .show {
  width: 1200px;
  height: 540px;
  margin: 30px 0 40px 0;
  display: flex;
}
.center > .show > .right {
  overflow: hidden;
  border-radius: 0 25px 25px 0;
}
.center > .show > .left {
  width: 400px;
  height: 540px;
  background-color: #f2f2f2;
  border-radius: 25px 0 0 25px;
  overflow: hidden;
}
.center > .show > .left > div {
  height: 180px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center > .show > .left > div :hover {
  background-color: #55cfe6;
  color: #ffffff;
  width: 400px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sele {
  background-color: #55cfe6;
  color: #ffffff;
  width: 400px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center > .show > .left > div > div {
  padding: 30px;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  box-sizing: border-box;
}

/* 
.center > .card {
  width: 1200px;
  margin: 24px auto 32px;
  display: flex;
  justify-content: space-around;
}
.center > .card > .item {
  width: 360px;
  height: 200px;
  border-radius: 20px;
  background-image: linear-gradient(white, white);
  display: flex;
  align-items: center;
}
.center > .card > .item:hover {
  background-image: linear-gradient(#55cfe6, #22aaef);
}
.center > .card > .item:hover > p {
  color: white;
}
.center > .card > .item > p {
  padding: 30px;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
} */

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}

/* .subswipe > img {
  height: 60px;
}
.subswipe > :nth-child(1) {
  z-index: 1;
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.subswipe > :nth-child(3) {
  z-index: 1;
  border-radius: 2px;
  position: absolute;
  right: 0;
  bottom: 0;
} */
.subswipe {
  width: 1200px;
  height: 60px;
  background-color: transparent;
  margin: 20px auto;
  overflow: hidden;
  display: flex;
  position: relative;
}
.subswipe > .list {
  background-color: transparent;
  width: 200%;
  height: 60px;
  display: flex;
  animation: 15s move infinite linear;
}
@keyframes move {
  0% {
    transform: translate(0px);
  }
  100% {
    transform: translate(-1200px);
  }
}

.subswipe > .list > div {
  width: 150px;
  height: 60px;
}
.subswipe > .list > div > img {
  width: 135px;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #8080807a;
}
</style>