import Vue from 'vue'
import VueRouter from 'vue-router'


//主页
import Index from '../views/Index.vue'
//IT 服务
import ITservice from '../views/ITservice.vue'
//软件研发
import Software from '../views/Software.vue'
//人工智能
import AI from '../views/AI.vue'
//合作案例
import Case from '../views/Case.vue'
//合作案例
import Education from '../views/Education.vue'
//关于约码
import About from '../views/About.vue'
//企业文化
import Culture from '../views/Culture.vue'
//联系我们
import Contact from '../views/Contact.vue'
//最新资讯
import Latest from '../views/Latest.vue'
//404
import FHF from '../views/404.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/itservice',
    name: 'ITservice',
    component: ITservice
  },
  {
    path: '/software',
    name: 'Software',
    component: Software
  },
  {
    path: '/ai',
    name: 'AI',
    component: AI
  },
  {
    path: '/case',
    name: 'Case',
    component: Case
  },
  {
    path: '/education',
    name: 'Education',
    component: Education
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/culture',
    name: 'Culture',
    component: Culture
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/latest',
    name: 'Latest',
    component: Latest
  },

  {
    path: '*',
    name: 'FHF',
    component: FHF
  },
  //     path: '/about',
  //     name: 'About',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
