<template>
  <div class="about">
    <navbar></navbar>
    <div class="total">
      <div class="title">约码科技成立</div>
      <div class="main">
        <div>
          约码（山东）网络科技有限公司（简称：约码科技），创建于2021年4月19日，位于美丽泉城——济南。公司自创建以来
          一直为IT教育和发展做出贡献。向着成为IT行业一站式服务互联网企业的目标不断努力。
        </div>
        <div>
          约码科技专业从事人工智能、数据计算、数据挖掘、数据分析、软件研发、IT教育咨询产业的网络科技型、信息技术型企业，是行业内一致认可的高新技术企业、软件企业。
        </div>
      </div>
      <div>
        <img src="/img/约码科技公司.png" alt="" />
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
};
</script>

<style scoped>
.title {
  width: 144px;
  height: 24px;
  font-family: SimHei;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 96px;
  margin-left: 880px;
  margin-bottom: 32px;
}
.main {
  width: 1000px;
  height: 82px;
  font-family: SimHei;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 21px;
  letter-spacing: 0px;
  color: #676767;
  margin-left: 460px;
  text-indent: 2em;
  margin-bottom: 32px;
}
img {
  margin-left: 460px;
  width: 1000px;
  height: 750px;
}
</style>