<template>
  <div>
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <h1>企业文化</h1>
        <h3>CORPORATE CULTURE</h3>
      </div>
      <div class="center">
        <div class="title">
          <h1>CONCEPTS</h1>
          <h3 style="left: 33%">文化理念</h3>
        </div>
        <div class="content">
          <div class="item">
            <img src="/img/qymb.png" alt="" />
            <p>做IT行业一站式服务互联网企业</p>
          </div>
          <div class="item">
            <img src="/img/qyyy.png" alt="" />
            <p>
              让入门用户轻松学习体系、经典、真实项目的IT技术。<br />让职场用户快速提升新技术。<br />让所有用户发展路线更清晰。
            </p>
          </div>
          <div class="item">
            <img src="/img/qysm.png" alt="" />
            <p>为IT教育和发展做出贡献</p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">
          <h1>ACTIVITY</h1>
          <h3 style="left: 30%">文化活动</h3>
        </div>
        <div class="content">
          <div class="left">
            <img src="/img/srdg.png" alt="" />
            <div>
              <h1>生日会</h1>
            </div>
          </div>
          <div class="right">
            <div class="t">
              <img src="/img/a.png" alt="" />
              <div>公司团建</div>
            </div>
            <div class="b">
              <div class="item">
                <img src="/img/b.png" alt="" />
                <div>员工爱好</div>
              </div>
              <div class="item">
                <img src="/img/c.png" alt="" />
                <div>公司活动</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {};
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom > .content {
  width: 1200px;
  height: 600px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.bottom > .content > .right {
  width: 680px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom > .content > .right > .b {
  width: 680px;
  height: 220px;
  display: flex;
  justify-content: space-between;
}
.bottom > .content > .right > .b > .item {
  width: 320px;
  height: 220px;
  position: relative;
}
.bottom > .content > .right > .b > .item:hover > div {
  z-index: 2;
  animation: fade 1s infinite;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bottom > .content > .right > .b > .item > img {
  border-radius: 25px;
}
.bottom > .content > .right > .b > .item > div {
  line-height: 220px;
  font-size: 50px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  z-index: -1;
  width: 320px;
  height: 220px;
  background-color: #0000007d;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom > .content > .right > .t {
  width: 680px;
  height: 340px;
  border-radius: 20px;
  position: relative;
}
.bottom > .content > .right > .t:hover > div {
  z-index: 2;
  animation: fade 1s infinite;
}
.bottom > .content > .right > .t > div {
  line-height: 340px;
  font-size: 50px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  z-index: -1;
  width: 680px;
  height: 340px;
  background-color: #0000007d;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom > .content > .left {
  width: 480px;
  height: 600px;
  position: relative;
}
.bottom > .content > .left:hover > div {
  z-index: 2;
  animation: fade 1s infinite;
}
.bottom > .content > .left > img {
  width: 480px;
  height: 600px;
}
.bottom > .content > .left > div {
  border-radius: 20px;
  width: 480px;
  height: 600px;
  background-color: #0000007d;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}
.bottom > .content > .left > div > h1 {
  font-size: 50px;
  text-align: center;
  line-height: 500px;
  color: #fff;
  font-weight: 700;
}
.center {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center > .entry {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.center > .content {
  width: 1200px;
  height: 300px;
  margin-top: 32px;
  border: 5px solid #1fa8f0;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
}
.center > .content > .item {
  display: flex;
  height: 100%;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.center > .content > .item > img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.center > .content > .item > p {
  padding: 20px 40px;
  line-height: 23px;
}
.top {
  background-image: url("/img/CultureBG.png");
  width: 100%;
  height: 340px;
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top > h1 {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
@media screen and (max-width: 786px) {
  .container {
    width: 1200px;
  }
}
</style>