<template>
  <div>
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <img src="/img/ITjy.png" alt="" />
        <img src="/img/banner1a.png" alt="" />
      </div>
      <div class="center">
        <div class="title">
          <h1>STUDY</h1>
          <h3 style="left: 22%">体系学习</h3>
        </div>
        <div class="content">
          <div class="item">
            <div class="theme">
              <img src="/img/title1.png" alt="" />
            </div>
            <p>
              所有开发语言，从基础到框架、到整合、到经典项目，一条线梳理无断层，让IT学习更加轻松。
            </p>
            <img src="/img/system1.png" alt="" />
            <p>
              一站式IT学习轻松快速入门，无盲点、无断层形成知识体系，解决学习过程中的困惑和难点。
            </p>
          </div>
          <div class="item">
            <div class="theme">
              <img src="/img/title2.png" alt="" />
            </div>
            <p style="margin-right: 50px">
              改编企业真实项目，从需求调研到项目上线、到运维，并结合公司项目实际开展，让用户熟悉企业真正的研发过程。
            </p>
            <img src="/img/system2.png" alt="" />
            <p>
              对接各行各业的软件需求，完成企业实战项目，快速对项目进行技术选型，总结积累实战经验，快速提升自己。
            </p>
          </div>
          <div class="item">
            <div class="theme">
              <img src="/img/title3.png" alt="" />
            </div>
            <p>
              新的技术不断衍生和投入使用、对开源技术进行追加学习，并应用到实际案例中。
            </p>
            <img src="/img/system3.png" alt="" />
            <p>
              快速查找自身技能漏洞，定位、补缺对新技术的接受学习，让重点、难点学习更快、更准。
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="entry">
          <div class="title">
            <h1>ENTRY TO ENTRY</h1>
            <h3 style="left: 35%">入门到入职</h3>
          </div>
          <div class="content">
            <div class="item">
              <img src="/img/icon-dw.png" alt="" />
              <p>
                改变初学者学习路线迷茫、无从下手的学习方式，提供一站式免费体系学习路线、学习资源，并提供真实企业改编项目。
              </p>
            </div>
            <div class="item">
              <img src="/img/icon-fx.png" alt="" />
              <p>
                平台通过大数据分析，针对用户进行技能评估、解决企业招人难、团队人才流失严重的问题，帮助企业找到合适的技术人才，帮助用户找到满意的高薪工作。
              </p>
            </div>
            <div class="item">
              <img src="/img/icon-wt.png" alt="" />
              <p>
                入职后，平台为用户提供后续服务，解决用户入职后遇到职业迷茫，遇到项目无从下手，遇到困难无法解决的问题。导师线上为你解答！
              </p>
            </div>
          </div>
        </div>
        <div class="it">
          <div class="title">
            <h1>COUNSEL</h1>
            <h3 style="left: 23%">IT教育与咨询</h3>
          </div>
          <div class="content">
            <div class="item">
              <img src="/img/icon-dx.png" alt="" />
              <h1>面向对象</h1>
              <p>
                高校毕业生<br />
                IT从业人员<br />
                转行IT人员<br />
              </p>
            </div>
            <div class="item">
              <img src="/img/icon-fw.png" alt="" />
              <h1>服务范围</h1>
              <p>
                职业教育分为线上、线下两个模块。线上通过视频学习、实打实敲、理论加实战项目，线下通过面授、企业项目、导师指导进行学习。同时还为用户提供更安静舒适的学习环境
              </p>
            </div>
            <div class="item">
              <img src="/img/icon-xd.png" alt="" />
              <h1>职业规划向导</h1>
              <p>
                针对初学者学习路线模糊、初级从业者未来发展方向迷茫、高级从业者无法应对行业激烈竞争提供职业规划向导服务。解决用户学习、从业过程中难以解决的各种规划问题。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {};
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.bottom {
  width: 1200px;
  margin: 0 auto;
}
.bottom > .it {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.bottom > .it > .content {
  width: 1200px;
  height: 400px;
  margin-top: 32px;
  border: 5px solid #1fa8f0;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
}
.bottom > .it > .content > .item {
  display: flex;
  height: 100%;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.bottom > .it > .content > .item > img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.bottom > .it > .content > .item > p {
  padding: 20px 40px;
  line-height: 23px;
}
.bottom > .it > .content > .item > h1 {
  font-size: 24px;
  margin-top: 25px;
  color: #1fa8f0;
}
.bottom > .entry {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.bottom > .entry > .content {
  width: 1200px;
  height: 320px;
  margin-top: 32px;
  border: 5px solid #1fa8f0;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
}
.bottom > .entry > .content > .item {
  display: flex;
  height: 100%;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.bottom > .entry > .content > .item > img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.bottom > .entry > .content > .item > p {
  padding: 20px 40px;
  line-height: 23px;
}
.center {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1000px;
  background-image: url("/img/grayBG.png");
}
.center > .content {
  margin-top: 64px;
}
.center > .content > .item {
  width: 1200px;
  height: 200px;
  border-radius: 25px;
  box-shadow: 0 0 5px #000;
  position: relative;
  display: flex;
  margin-bottom: 100px;
}
.center > .content > .item:hover {
  background-image: linear-gradient(#55cfe6, #22aaef);
}
.center > .content > .item:hover > p {
  color: #fff;
}
.center > .content > .item > .theme {
  position: absolute;
  left: -50px;
  top: -43px;
}
.center > .content > .item > img {
  width: 280px;
  height: 170px;
  margin: auto;
}
.center > .content > .item :nth-child(4) {
  margin: auto 40px;
  color: #1fa8f0;
  line-height: 22px;
}
.center > .content > .item :nth-child(2) {
  width: 300px;
  line-height: 22px;
  padding: 61px 40px 0;
}
.top {
  background-image: url("/img/banner3.png");
  width: 100%;
  height: 450px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.top > img {
  height: 350px;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
  }
}
</style>