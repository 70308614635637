<template>
  <div class="case">
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <h1>合作案例</h1>
        <h3>COOPERATION</h3>
      </div>
      <div class="bottom">
        <div class="sele">
          <div :class="{ active: nav == 1 }" @click="clicknav(1)">环保</div>
          <div :class="{ active: nav == 2 }" @click="clicknav(2)">教育</div>
          <div :class="{ active: nav == 3 }" @click="clicknav(3)">购物</div>
          <div :class="{ active: nav == 4 }" @click="clicknav(4)">安全</div>
          <div :class="{ active: nav == 5 }" @click="clicknav(5)">油站</div>
          <div :class="{ active: nav == 6 }" @click="clicknav(6)">酒店</div>
          <div :class="{ active: nav == 7 }" @click="clicknav(7)">医疗</div>
          <div :class="{ active: nav == 8 }" @click="clicknav(8)">数字藏品</div>
          <div :class="{ active: nav == 9 }" @click="clicknav(9)">物业</div>
        </div>
        <div class="content">
          <div class="a" v-show="active == 1">
            <div class="a1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/dlj1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj6.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj7.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj8.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/dlj9.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="a2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht1.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht2.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht3.png" style="width: 520px" alt="" />
                  </el-carousel-item>

                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht5.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <div class="a" v-show="active == 2">
            <div class="b1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/yh1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh6.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh7.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh8.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh9.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yh10.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="b2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht8.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht7.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht6.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/ht9.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>

          <div class="a" v-show="active == 3">
            <div class="a1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/u1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u6.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/u7.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                   
                </el-carousel>
              </div>
            </div>
            <div class="a2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc1.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc2.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc3.png" style="width: 520px" alt="" />
                  </el-carousel-item>

                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc4.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                   <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc5.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                   <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/upc6.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>

          <div class="a" v-show="active == 4">
            
            <div class="b2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/a1.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/a2.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/a3.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/a4.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="b2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/apc1.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/apc2.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/apc3.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/apc4.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>

          </div>

          <div class="a" v-show="active == 5">
            <div class="a1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/yz1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz6.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/yz7.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                   
                </el-carousel>
              </div>
            </div>
            <div class="a2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yzpc1.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yzpc2.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yzpc3.png" style="width: 520px" alt="" />
                  </el-carousel-item>
 
                </el-carousel>
              </div>
            </div>
          </div>
          
          <div class="a" v-show="active == 6">
            <div class="b1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/jd1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/jd2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/jd3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/jd4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  
                </el-carousel>
              </div>
            </div>
            <div class="b2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/jdpc1.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/jdpc2.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/jdpc3.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                 
                </el-carousel>
              </div>
            </div>
          </div>


          <div class="a" v-show="active == 7">
            
            <div class="b2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yl1.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yl2.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yl3.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/yl4.jpg" style="width: 520px" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>

          <div class="a" v-show="active == 8">
            <div class="a1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/sz1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/sz2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/sz3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/sz4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/sz5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                 
                </el-carousel>
              </div>
            </div>
            <div class="a2">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="380px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/szpc1.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/szpc2.png" style="width: 520px" alt="" />
                  </el-carousel-item>
                  <el-carousel-item
                    style="height: 380px; width: 500px; left: 7%"
                  >
                    <img src="/img/szpc3.png" style="width: 520px" alt="" />
                  </el-carousel-item>

                  
                </el-carousel>
              </div>
            </div>
          </div>



          <div class="a" v-show="active == 9">
            <div class="b1">
              <div class="swipe">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="480px"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <img
                      src="/img/wy1.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/wy2.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/wy3.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/wy4.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  <el-carousel-item>
                    <img
                      src="/img/wy5.png"
                      style="height: 480px; width: 241px"
                      alt=""
                    />
                  </el-carousel-item>
                  
                </el-carousel>
              </div>
            </div>
         
          </div>







        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {
      active: 1,
      num: 1,
      activeName: "second",
      nav: 1,
    };
  },
  methods: {
    clicknav(i) {
      this.nav = i;
      this.active = i;
    },
    clickb(i) {
      this.x = i;
    },
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.case {
  overflow: hidden;
}
.el-carousel {
  width: 1500px;
  margin: auto;
}
.el-carousel__item {
  width: 241px;
  height: 480px;
  border-radius: 50px;
  left: 15%;
}

.content {
  margin-top: 20px;
}

.b1 {
  background-image: url("/img/EngliBG1.png");
  border-radius: 30px;
  height: 550px;
  width: 1710px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}
.b2 {
  background-image: url("/img/EngliBG2.png");
  border-radius: 30px;
  height: 550px;
  width: 1710px;
  position: relative;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}
.a1 {
  background-image: url("/img/dljBG.png");
  border-radius: 30px;
  height: 550px;
  width: 1710px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}
.a2 {
  background-image: url("/img/dljBG.png");
  border-radius: 30px;
  height: 550px;
  width: 1710px;
  position: relative;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.bottom {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.click {
  height: 5px;
  width: 30px;
  border-radius: 25px;
  background-color: #1fa8f0;
  position: absolute;
  top: 45%;
  z-index: 2;
}
.subclick {
  height: 5px;
  width: 3em;
  border-radius: 25px;
  background-color: #1fa8f0;
  position: absolute;
  top: 85%;
  z-index: 2;
}
.bottom > .sele {
  width: 1200px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  background-color: #f5f7fa;
}
.bottom > .sele > div {
  width: 130px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  transition: 0.2s;
}
.bottom > .sele > div:hover {
  background-color: #1fa7f0a2;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
}
.active {
  background-color: #1fa7f0a2;
  color: #ffffff;
}
.top {
  background-image: url("/img/caseBG.png");
  width: 100%;
  height: 340px;
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top > h1 {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
    overflow: hidden;
  }
  .case {
    overflow: visible;
  }
}
</style>