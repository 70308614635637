<template>
  <div class="fhf">
    <img src="/img/404.jpg" alt="" />
    <div class="btn" @click="back"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.fhf {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.fhf > img {
  width: 100%;
  height: 100vh;
}
.fhf > .btn {
  width: 71px;
  height: 57px;
  position: absolute;
  top: 49%;
  right: 28%;
}
.fhf > .btn:hover {
  cursor: pointer;
}
</style>





