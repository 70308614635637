<template>
  <div class="head">
    <div class="boxNavbar">
      <div class="navbar">
        <img
          src="../assets/img/ymkj.svg"
          alt=""
          style="height: 55px; margin: auto 0; min-width: 160px"
        />
        <ul class="navbar-title" @click="click">
          <li :class="show == 1 ? 'active' : ''">首页</li>
          <li :class="show == 2 ? 'active' : ''">IT服务</li>
          <li :class="show == 3 ? 'active' : ''">软件研发</li>
          <li :class="show == 4 ? 'active' : ''">人工智能</li>
          <li :class="show == 5 ? 'active' : ''">合作案例</li>
          <li :class="show == 6 ? 'active' : ''">约码教育</li>
          <li :class="show == 7 ? 'active' : ''" class="down">
            <p @click="goAbout">关于约码</p>
            <div class="drop">
              <div class="item" @click="goCulture">
                <p>企业文化</p>
              </div>
              <div class="item" @click="goContact">
                <p>联系我们</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: 1,
      scroll: false,
    };
  },
  methods: {
    goAbout() {},
    goCulture() {
      this.$router.push("/Culture");
    },
    goContact() {
      this.$router.push("/Contact");
    },
    click(i) {
      // console.log(i.target.innerHTML);
      if (i.target.innerHTML == "IT服务") {
        this.$router.push("/itservice");
      } else if (i.target.innerHTML == "首页") {
        this.$router.push("/");
      } else if (i.target.innerHTML == "软件研发") {
        this.$router.push("/software");
      } else if (i.target.innerHTML == "人工智能") {
        this.$router.push("/ai");
      } else if (i.target.innerHTML == "合作案例") {
        this.$router.push("/case");
      } else if (i.target.innerHTML == "约码教育") {
        this.$router.push("/education");
      } else if (i.target.innerHTML == "关于约码") {
        this.$router.push("/about");
      }
    },
  },
  mounted() {
    name = this.$route.name;
    if (name == "ITservice") {
      this.show = 2;
    } else if (name == "Index") {
      this.show = 1;
    } else if (name == "Software") {
      this.show = 3;
    } else if (name == "AI") {
      this.show = 4;
    } else if (name == "Case") {
      this.show = 5;
    } else if (name == "Education") {
      this.show = 6;
    } else if (name == "About") {
      this.show = 7;
    } else if (name == "Culture") {
      this.show = 7;
    } else if (name == "Contact") {
      this.show = 7;
    }
  },
};
</script>

<style scoped>
.down:hover .drop {
  display: block;
}
.drop {
  height: 128px;
  width: 120px;
  background-color: #00000086;
  display: none;
}
/* 导航 */
.head {
  margin-bottom: 64px;
}

.boxNavbar {
  z-index: 10;
  /* margin-bottom: 5px; */
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px #707070;
  position: fixed;
  top: 0;
  left: 0;
}
.navbar {
  width: 1200px;
  margin: 0 auto;
  height: 64px;
  display: flex;
  justify-content: space-around;
}
.navbar > .navbar-title {
  display: flex;
}
.navbar > .navbar-title > li {
  color: #000000;
  font-size: 18px;
  line-height: 64px;
  width: 120px;
  text-align: center;
}
.navbar > .navbar-title > li:hover {
  background-color: #1fa8f0;
  color: #ffffff !important;
  cursor: pointer;
}
.active {
  background-color: #1fa8f0;
  color: #ffffff !important;
}
/*导航 */

@media screen and (max-width: 768px) {
  .navbar {
    width: 1200px;
  }
  .boxNavbar {
    width: 1200px;
  }
}
</style>