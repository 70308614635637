<template>
  <div>
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <h1>联系我们</h1>
        <h3>CONTACT US</h3>
      </div>
      <div class="bottom">
        <div class="title">
          <h1>ADDRESS</h1>
          <h3 style="left: 32%">公司地址</h3>
        </div>
      </div>
    </div>
    <div class="map" id="map"></div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {};
  },
  mounted() {
    scrollTo(0, 0);
    var map = new BMapGL.Map("map"); // 创建地图实例
    map.centerAndZoom(new BMapGL.Point(117.080232, 36.751818), 18); // 初始化地图，设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    map.setHeading(64.5); //设置地图旋转角度
    map.setTilt(10); //设置地图的倾斜角度
    // map.disableDragging(); //禁止拖拽
    // map.disableScrollWheelZoom(); //禁止缩放

    /*******************/
    var content = `
    <div>
      <img src="/img/concat.png" style="width: 280px " alt="" />
    </div>`;
    var label = new BMapGL.Label(content, {
      // 创建文本标注
      position: new BMapGL.Point(117.080232, 36.751818),
      offset: new BMapGL.Size(-310, -220),
    });
    map.addOverlay(label); // 将标注添加到地图中
    label.setStyle({
      // 设置label的样式
      backgroundColor: "transparent",
      width: "280px",
      height: "100px",
      color: "#000",
      border: "0px solid white",
    });

    // var map = new BMapGL.Map('map');
    // map.centerAndZoom(new BMapGL.Point(117.080828, 36.750959), 18);
    // map.enableScrollWheelZoom(true);
    // // 创建添加点标记
    // var marker = new BMapGL.Marker(new BMapGL.Point(117.080828, 36.750959));
    // map.addOverlay(marker);
    // // 创建图文信息窗口
    // var sContent = `<div>
    // <img style='float:right;margin:0 4px 22px'  src='/img/concat.png'/>
    // </div>`;
    // var infoWindow = new BMapGL.InfoWindow(sContent);
    // // marker添加点击事件
    // marker.addEventListener('click', function () {
    //     this.openInfoWindow(infoWindow);
    //     infoWindow.redraw();
    //     // 图片加载完毕重绘infoWindow
        
    // });
  },
};
</script>
<style scoped>
.bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map {
  margin: 40px auto;
  width: 1920px;
  height: 500px;
}
.top {
  background-image: url("/img/ContactBG.png");
  width: 100%;
  height: 340px;
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top > h1 {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}

@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
  }
  .map {
    width: 1200px;
  }
}
</style>
