<template>
  <div class="software">
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <img src="/img/banner3a.png" alt="" />
        <img src="/img/rjyf.png" alt="" />
      </div>
      <div class="bottom">
        <!-- 研发范围 -->
        <div class="scope">
          <div class="title" style="margin: 30px 0">
            <h1>COOPERATION</h1>
            <h3 style="left: 45.5%">研发范围</h3>
          </div>
          <div class="content">
            <div class="item">
              <div class="package">
                <img src="/img/icon-iphone.png" alt="" />
                <div class="text">
                  <p>APP</p>
                  <p>小程序</p>
                  <p>公众号</p>
                  <p>官方网站</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="package">
                <img src="/img/icon-ht.png" alt="" />
                <div>
                  <p>OA(办公管理平台)</p>
                  <p>CRM(客户管理管理)</p>
                  <p>ERP(企业资源管理)</p>
                  <p>ERM(企业绩效管理)</p>
                  <p>HR(人力资源管理)</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="package">
                <img src="/img/icon-cw.png" alt="" />
                <div>
                  <p>电商、零售</p>
                  <p>财务、供应链</p>
                  <p>OMS(订单系统)</p>
                  <p>TMS(运输管理系统)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 专业团队 -->
        <div class="team">
          <div class="title zy">
            <h1>TEAM</h1>
            <h3 style="left: 47.5%">专业团队</h3>
          </div>
          <div class="content">
            <div class="on">
              <div>
                <div>PM</div>
                <img src="/img/zy1.png" alt="" />
                <p>
                  经验丰富的项目经理，为企业平台的新需求提供专业的解决方案，精确把控项目进度
                </p>
              </div>
              <div>
                <div>UI</div>
                <img src="/img/zy2.png" alt="" />
                <p>
                  审美独到的UI设计师，将客户的构思锦上添花，做用户满意的用户体验
                </p>
              </div>
              <div>
                <div>DEV</div>
                <img src="/img/zy3.png" alt="" />
                <p>
                  高效的技术研发团队，迅速响应企业对平台的专业需求，不放过每一个Bug
                </p>
              </div>
            </div>
            <div class="bo">
              <div>
                <div>QA</div>
                <img src="/img/zy4.png" alt="" />
                <p>
                  专业严谨的测试人员，严格把控客户的每一项质量要求，细微之处见真章
                </p>
              </div>
              <div>
                <div>OPS</div>
                <img src="/img/zy5.png" alt="" />
                <p>
                  完善的运维团队，实时处理客户问题，为企业平台7x24小时稳定运行提供安全的保障
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 专业流程 -->
        <div class="flow">
          <div class="title" style="margin: 30px 0">
            <h1>FLOW</h1>
            <h3 style="left: 46%">专业流程</h3>
          </div>
          <div class="content">
            <div class="one">
              <div class="a1">
                <p @mouseover="clickli(0)" :class="{ text1: sele == 0 }">
                  需求收集
                </p>
                <p @mouseover="clickli(2)" :class="{ text1: sele == 2 }">
                  概要设计
                </p>
                <p @mouseover="clickli(4)" :class="{ text1: sele == 4 }">
                  代码实现
                </p>

                <p @mouseover="clickli(6)" :class="{ text1: sele == 6 }">
                  内部验收
                </p>
                <p @mouseover="clickli(8)" :class="{ text1: sele == 8 }">
                  业务测试
                </p>
                <p @mouseover="clickli(10)" :class="{ text1: sele == 10 }">
                  完成测试
                </p>
                <p @mouseover="clickli(12)" :class="{ text1: sele == 12 }">
                  上线实施
                </p>
                <p
                  @mouseover="clickliOnly"
                  @mouseout="clickover"
                  :class="{ text1: only }"
                >
                  整体回盘
                </p>
              </div>
              <ul>
                <li @mouseover="clickli(0)" :class="{ garden: sele == 0 }"></li>
                <li
                  @mouseover="clickli(1)"
                  style="left: 80px"
                  :class="{ garden: sele == 1 && !only }"
                ></li>
                <li
                  @mouseover="clickli(2)"
                  style="left: 160px"
                  :class="{ garden: sele == 2 && !only }"
                ></li>
                <li
                  @mouseover="clickli(3)"
                  style="left: 240px"
                  :class="{ garden: sele == 3 && !only }"
                ></li>
                <li
                  @mouseover="clickli(4)"
                  style="left: 320px"
                  :class="{ garden: sele == 4 && !only }"
                ></li>
                <li
                  @mouseover="clickli(5)"
                  style="left: 400px"
                  :class="{ garden: sele == 5 && !only }"
                ></li>
                <li
                  @mouseover="clickli(6)"
                  style="left: 480px"
                  :class="{ garden: sele == 6 && !only }"
                ></li>
                <li
                  @mouseover="clickli(7)"
                  style="left: 560px"
                  :class="{ garden: sele == 7 && !only }"
                ></li>
                <li
                  @mouseover="clickli(8)"
                  style="left: 640px"
                  :class="{ garden: sele == 8 && !only }"
                ></li>
                <li
                  @mouseover="clickli(9)"
                  style="left: 720px"
                  :class="{ garden: sele == 9 && !only }"
                ></li>
                <li
                  @mouseover="clickli(10)"
                  style="left: 800px"
                  :class="{ garden: sele == 10 && !only }"
                ></li>
                <li
                  @mouseover="clickli(11)"
                  style="left: 880px"
                  :class="{ garden: sele == 11 && !only }"
                ></li>
                <li
                  @mouseover="clickli(12)"
                  style="left: 960px"
                  :class="{ garden: sele == 12 && !only }"
                ></li>
                <li
                  @mouseover="clickli(13)"
                  style="left: 1040px"
                  :class="{ garden: sele == 13 && !only }"
                ></li>
                <li
                  @mouseover="clickliOnly"
                  @mouseout="clickover"
                  style="left: 1120px"
                  :class="{ garden: only }"
                ></li>
              </ul>
              <div class="a2">
                <p
                  @mouseover="clickli(1)"
                  :class="{ text1: sele == 1 && !only }"
                >
                  需求评审
                </p>
                <p
                  @mouseover="clickli(3)"
                  :class="{ text1: sele == 3 && !only }"
                >
                  方案评审
                </p>
                <p
                  @mouseover="clickli(5)"
                  :class="{ text1: sele == 5 && !only }"
                >
                  技术自测
                </p>
                <p
                  @mouseover="clickli(7)"
                  :class="{ text1: sele == 7 && !only }"
                >
                  提交测试
                </p>
                <p
                  @mouseover="clickli(9)"
                  :class="{ text1: sele == 9 && !only }"
                >
                  需求变更
                </p>
                <p
                  @mouseover="clickli(11)"
                  :class="{ text1: sele == 11 && !only }"
                >
                  上线准备
                </p>
                <p
                  @mouseover="clickli(13)"
                  :class="{ text1: sele == 13 && !only }"
                >
                  上线验证
                </p>
              </div>
            </div>
            <div class="tow">
              <div class="t" :class="{ anim }" @animationend="animover">
                <div class="t1">
                  <h1>{{ items[selet].title }}</h1>
                  <div></div>
                </div>
                <div class="t2">
                  <p>
                    {{ items[selet].content }}
                  </p>
                  <p>{{ items[selet].output }}</p>
                </div>
              </div>
              <div class="subt" :class="{ anims }" @animationend="animover">
                <div class="t1">
                  <h1>{{ items[sele + 1].title }}</h1>
                  <div></div>
                </div>
                <div class="t2">
                  <p>
                    {{ items[sele + 1].content }}
                  </p>
                  <p>
                    {{ items[sele + 1].output }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot class="foot" style="margin-top: 0"></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {
      anim: false,
      anims: false,
      only: false,
      selet: 0,
      sele: 0,
      items: [
        {
          fid: 1,
          title: "需求收集",
          content:
            "• 来自产品经理提供的需求、内部开发团队提供的需求以及线上BUG",
          output: "• 输出：需求文档，原型图（取决于需求的复杂程度）",
        },
        {
          fid: 2,
          title: "需求评审",
          content:
            "• 与需求提出方确认细节以及开发人员进行可行性分析参与人员：产品经理、测试人员、开发人员、项目经理",
          output: "• 输出：调整后的需求文档",
        },
        {
          fid: 3,
          title: "概要设计",
          content: "• 开发人员制定概要设计并进行工作量评估",
          output: "• 输出：概要设计文档",
        },
        {
          fid: 4,
          title: "方案评审",
          content:
            "• 技术负责人进行方案评审，需确认后才可开始编码项目经理进行开发排期并告知产品经理",
          output: "• 输出：调整后的概要设计文档、开发排期安排表",
        },
        {
          fid: 5,
          title: "代码实现",
          content:
            "• 开发人员进行开发项目经理定期确认开发进度，若有延期可能需执行措施并告知产品经理技术负责人对代码进行评审",
          output: "• 输出：开发成果",
        },
        {
          fid: 6,
          title: "技术自测",
          content:
            "• 开发人员部署到SIT环境进行自测相关模块进行技术联调（此步非必须）",
          output: "",
        },
        {
          fid: 7,
          title: "内部验收",
          content: "• 开发人员部署到UAT环境给其他开发人员或者项目经理验收测试",
          output: "",
        },
        {
          fid: 8,
          title: "提交测试",
          content: "• 项目经理提交成果给测试人员进行测试，同时提供账号、环境等",
          output: "",
        },
        {
          fid: 9,
          title: "业务测试",
          content:
            "• 测试人员把测试过程中发现的bug提交到禅道开发人员尽可能当天解决bug测试一轮后开发人员部署到预演环境，进行回归测试",
          output: "",
        },
        {
          fid: 10,
          title: "需求变更",
          content:
            "• 需求变更多发生在这阶段，需先对变更进行是否必要的评估，随后再重新进行需求评审等步骤项目经理根据变更的情况重新执行排期计划",
          output: "",
        },
        {
          fid: 11,
          title: "完成测试",
          content:
            "• 测试人员提供给项目经理测试报告以及确定上线范围产品经理安排上线验证人员",
          output: "• 输出：测试报告",
        },
        {
          fid: 12,
          title: "上线准备",
          content:
            "• 项目经理准备上线材料并与IT过步骤开发人员提供上线步骤以及打包把相关材料上传运维平台",
          output: "• 输出：上线申请表、电子单、上线步骤、上线包",
        },
        {
          fid: 13,
          title: "上线实施",
          content: "• 运维人员上线实施开发人员留下进行技术支持",
          output: "",
        },
        {
          fid: 14,
          title: "上线验证",
          content:
            "• 之前安排的验证人员进行业务验证，并反馈结果到项目经理项目经理确认每点都验证通过后完成上线",
          output: "",
        },
        {
          fid: 15,
          title: "整体回盘",
          content:
            "• 项目经理组织相关人员进行项目回盘，回顾做的不足以及流程上需要优化的地",
          output: "",
        },
      ],
    };
  },
  methods: {
    clickli(i) {
      this.sele = i;
      this.selet = i;
      // this.anim = false;
      // this.anims = false;
      // this.anim = true;
      // this.anims = true;
    },
    animover() {
      // this.anim = false;
      // this.anims = false;
      // console.log(`动画结束`);
    },
    clickover() {
      this.only = false;
    },
    clickliOnly(i) {
      this.only = true;
      this.sele = -1;
      this.selet = 14;
      // this.anim = true;
      // this.anims = true;
    },
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.flow > .content {
  background-image: url("/img/bigBG.png");
  width: 1920px;
  height: 642px;
  margin: 10px 0 0 -365px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flow > .content p:hover {
  cursor: pointer;
}
.flow > .content li:hover {
  cursor: pointer;
}
.flow > .content > .tow {
  height: 390px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
@keyframes move {
  0% {
    margin-left: 0%;
  }
  25% {
    margin-left: -100%;
    opacity: 0;
  }
  26% {
    margin-left: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes movea {
  0% {
    position: absolute;
    left: 1300px;
  }
  25% {
    left: 500px;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.anim {
  animation-name: move;
  animation-duration: 2s;
}

.anims {
  animation-name: movea;
  animation-duration: 2s;
}

.flow > .content > .tow > .t > .t2 {
  margin-top: 40px;
}

.flow > .content > .tow > .t > .t2 > p {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  width: 500px;
  padding-left: 5px;
  text-indent: -10px;
}
.flow > .content > .tow > .t > .t1 {
  margin-top: 80px;
  position: relative;
}
.flow > .content > .tow > .t > .t1 > h1 {
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 2px;
}
.flow > .content > .tow > .t > .t1 > div {
  height: 4px;
  width: 125px;
  position: absolute;
  top: 38px;
  background-color: #1fa8f0;
}
.flow > .content > .tow > .subt > .t2 {
  margin-top: 30px;
}

.flow > .content > .tow > .subt > .t2 > p {
  color: #ffffff77;
  font-size: 18px;
  line-height: 26px;
  width: 500px;
  padding-left: 5px;
  text-indent: -10px;
}
.flow > .content > .tow > .subt > .t1 {
  margin-top: 80px;
  position: relative;
}
.flow > .content > .tow > .subt > .t1 > h1 {
  color: #ffffff77;
  font-size: 24px;
  letter-spacing: 2px;
}
.flow > .content > .tow > .subt > .t1 > div {
  height: 4px;
  width: 105px;
  position: absolute;
  top: 30px;
  background-color: #1fa7f0a1;
}
.flow > .content > .one {
  height: 256px;
  width: 1200px;
  /* background-color: #dfdfdf; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flow > .content > .one > ul {
  width: 1130px;
  height: 2px;
  background-color: #ffffff;
  position: relative;
}
.flow > .content > .one > ul > .garden {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: -7.5px;
}
.flow > .content > .one > ul > li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffffd3;
  position: absolute;
  top: -5px;
}

.text1 {
  font-size: 26px !important;
  font-weight: 900;
}
.flow > .content > .one > div {
  height: 100px;
  width: 1200px;
}
.flow > .content > .one > .a2 {
  width: 1062px;
  display: flex;
  justify-content: space-between;
}
.flow > .content > .one > .a2 > p {
  width: 10%;
  font-size: 24px;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
}
.flow > .content > .one > .a1 {
  display: flex;
  justify-content: space-between;
  width: 1250px;
  margin-top: 50px;
}
.flow > .content > .one > .a1 > p {
  width: 10%;
  font-size: 24px;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
}
.software {
  overflow: hidden;
}
.team::before {
  content: "";
  width: 1920px;
  height: 30px;
  margin-left: -365px;
  background-color: #f2f2f2;
}
.zy {
  width: 1920px;
  margin-left: -365px;
  background-color: #f2f2f2;
}
.team {
  margin: 40px auto;
  width: 1200px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
}
.team > .content {
  background-color: #f2f2f2;
  width: 1920px;
  margin: 0 -365px;
}
.team > .content > .on {
  width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}
.team > .content > .on > div {
  width: 320px;
  height: 280px;
  position: relative;
  border: 1px solid #80808040;
  background-color: #ffffff;
  border-radius: 20px;
}
.team > .content > .on > div > div {
  width: 48px;
  height: 48px;
  background-color: #1fa8f0;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  position: absolute;
  top: -24px;
  left: 140px;
  z-index: 2;
}
.team > .content > .on > div > img {
  position: absolute;
  left: -20px;
  top: -33px;
}
.team > .content > .on > div > p {
  position: absolute;
  bottom: 0;
  padding: 24px;
  line-height: 20px;
}
.team > .content > .bo > div {
  width: 320px;
  height: 280px;
  position: relative;
  border: 1px solid #80808040;
  background-color: #ffffff;
  border-radius: 20px;
}
.team > .content > .bo > div > div {
  width: 48px;
  height: 48px;
  background-color: #1fa8f0;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  position: absolute;
  top: -24px;
  left: 140px;
  z-index: 2;
}
.team > .content > .bo > div > img {
  position: absolute;
  left: -20px;
  top: -33px;
}
.team > .content > .bo > div > p {
  position: absolute;
  bottom: 0;
  padding: 24px;
  line-height: 20px;
}
.team > .content > .bo {
  width: 780px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}

.flow {
  margin: 40px auto 0px;
  width: 1200px;
}
.scope {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.scope::before {
  content: "";
}
.scope > .content {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.scope > .content > .item {
  width: 300px;
  height: 200px;
  border-radius: 20px;
  background-image: linear-gradient(#55cfe6, #22aaef);
  display: flex;
  align-items: center;
  justify-content: center;
}
.scope > .content > .item > .package {
  width: 260px;
  height: 160px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scope > .content > .item > .package > img {
  width: 100px;
}
.scope > .content > .item > .package > div > p {
  width: 150px;

  line-height: 20px;
  font-weight: 600;
  margin-right: 15px;
}
.scope > .content > .item .text {
  margin-left: 50px;
}

.top {
  background-image: url("/img/banner1.png");
  background-size: cover;

  width: 100%;
  height: 450px;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.top > img {
  height: 350px;
}
.bottom {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
  text-align: center;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
    overflow: hidden;
  }
  .software {
    overflow: visible;
  }
  .team > .content {
    width: 1200px;
    margin: 50px 0 0 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .team > .content > .item {
    margin: 25px;
  }

  .foot {
    margin-top: 410px;
  }
  .flow > .content > .one > .a1 {
    width: 1150px;
  }
  .flow > .content > .tow {
    justify-content: space-around;
  }
}
</style>